import "core-js/stable";
import "regenerator-runtime/runtime";
import Utils from "./utils";
import { preload } from "./preload";
import Blocks from "../../blocks";
import Shortcodes from "../../shortcodes";
import barba from "@barba/core";
import anime from "animejs/lib/anime.es.js";

(function () {
  function init() {
    if (window.wp === undefined)
      window.wp = {
        blocks: undefined,
        shortcodes: undefined,
        utils: undefined,
      };

    if (window.wp.utils === undefined) window.wp.utils = new Utils();

    if (window.wp.blocks === undefined) window.wp.blocks = new Blocks();

    if (window.wp.shortcodes === undefined)
      window.wp.shortcodes = new Shortcodes();

    const buttons = document.querySelectorAll(".toggle-button");
    buttons.forEach((button) => {
      button.addEventListener("click", toggle_menu);
    });
    window.wp.blocks.init();
    window.wp.shortcodes.init();
  }

  function boot() {
    init();

    const duration = 1000;
    barba.hooks.before(() => {
      barba.wrapper.classList.add("is-animating");
    });
    barba.hooks.after(() => {
      barba.wrapper.classList.remove("is-animating");
    });
    barba.init({
      cacheIgnore: ["/wp-admin/post.php", "/wp-admin"],
      debug: true,
      timeout: 10000,
      transitions: [
        {
          sync: true,
          leave: (data) => animation(data, duration * 0.5, 0),
          beforeEnter({ next }) {
            next.container.style.zIndex = -1;
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            const body = document.body;
            const toogle = body.classList.contains("menu-active");
            if (toogle) {
              body.classList.remove("menu-active");
            }
          },
          beforeLeave({ next }) {
            const elem = document.querySelector(
              `[href='${next.url.href}'].barba-card`
            );
            if (elem) {
              const nav = document.querySelector(`#site-navigation`);
              return smoothScroll(nav);
            }
          },
          after() {
            init();
          },
          afterLeave({ next }) {
            next.container.style.zIndex = 0;
            const body = document.querySelector(`body`);
            body.style.overflow = "visible";
          },
        },
      ],
      prevent: ({el, href}) => (href.includes("wp-admin")),
    });

    function noBarbaLoadButtons() {
      const items = Array.from(document.querySelectorAll(".no-barba-load .button-action"));

      if (items.length > 0) { // Check if buttons are present
        items.forEach((item, index) => {
          item.addEventListener("click", () => {
            const href = item.getAttribute("href");
            if (href) {
              barba.history.add(window.location.href, item);
              barba.go(href, item).then(() => {
              });
            }
          });
        });
      } else {
        console.log('No buttons were found');
      }
    }

// Run the function initially on page load
    noBarbaLoadButtons();

// Then run the function again each time a Barba transition completes
    barba.hooks.after(() => {
      noBarbaLoadButtons();
    });

  }

  function animation(data, duration) {
    const elem = document.querySelector(
      `[href='${data.next.url.href}'] .cases__carousel__item__image`
    );
    if (elem) {
      const body = document.querySelector(`body`);
      const carousel = document.querySelector(`.cases__carousel`);
      body.style.overflow = "hidden";
      carousel.style.overflow = "visible";
      carousel.style.zIndex = 2;
      elem.style["transition-duration"] = "0s";
      const rect = elem.getBoundingClientRect();
      const anim = anime({
        autoplay: false,
        targets: `[href='${data.next.url.href}'] .cases__carousel__item__image`,
        translateX: {
          value: -rect.x,
          duration: duration * 0.5,
        },
        translateY: {
          value: -rect.y,
          duration: duration,
          delay: duration * 0.5,
        },
        width: {
          value: [elem.clientWidth, document.body.scrollWidth],
          duration: duration * 0.5,
        },
        height: {
          value: [elem.clientHeight, document.body.scrollHeight],
          duration: duration,
          delay: duration * 0.5,
        },
        easing: "linear",
        endDelay: duration,
      });
      setTimeout(anim.play, duration);

      return anim.finished;
    }
  }

  function smoothScroll(elem, offset = 0) {
    const rect = elem.getBoundingClientRect();
    let targetPosition = Math.floor(rect.top + self.pageYOffset + offset);
    window.scrollTo({
      top: targetPosition,
    });

    return new Promise((resolve, reject) => {
      const failed = setTimeout(() => {
        reject();
      }, 2000);

      const scrollHandler = () => {
        if (self.pageYOffset === targetPosition) {
          window.removeEventListener("scroll", scrollHandler);
          clearTimeout(failed);
          resolve();
        }
      };
      if (self.pageYOffset === targetPosition) {
        clearTimeout(failed);
        resolve();
      } else {
        window.addEventListener("scroll", scrollHandler);
        elem.getBoundingClientRect();
      }
    });
  }

  function toggle_menu() {
    const body = document.body;
    const toogle = body.classList.contains("menu-active");
    if (toogle) {
      body.classList.remove("menu-active");
    } else {
      body.classList.add("menu-active");
    }
  }




  document.addEventListener("DOMContentLoaded", function () {
    preload(({ p }) => console.log(p)).then(boot);
  });
})();
