import Hero from "./hero/js";
import News from "./news-list/js";
import Facts from "./quick-facts/js";
import Slider from "./slider/js";
import Jobs from "./jobs-filter/js";
import Cases from "./cases/js";
import Employee from "./employee-contact-card/js";
import Benefits from "./competencies-benefits/js";
import HeadlineText from "./headline-text/js";
import IconList from "./icon-grid/js";
import Contact from "./contact/js";

export default class Blocks {
  init() {
    new Hero();
    new News();
    new Facts();
    new Slider();
    new Jobs();
    new Cases();
    new Employee();
    new Benefits();
    new HeadlineText();
    new IconList();
    new Contact();
  }
}